<app-header></app-header>

<section class="page-title bg-title-polregl overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>Politicas y reglamentos</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
					<li class="breadcrumb-item"><a href="index.html">Principal</a></li>
					<li class="breadcrumb-item active">Politicas y reglamentos</li>
				</ol>
			</div>
		</div>
	</div>
</section>



<!-- Pricing table start -->
<section id="pricing" class="pricing">
	<div class="container">
		<div class="row">
			<div class="col-md-12 heading">
				<span class="title-icon float-left"><i class="fa fa-university"></i></span>
				<h2 class="title">NORMATIVAS <span class="title-desc">Dirección Institucional
						de Posgrado e investigación</span></h2>
			</div>
		</div><!-- Title row end -->


		<div class="widget widget-tab">
			<ul class="nav nav-tabs">
				<li class="nav-item"><a class="nav-link active" href="#tab1" data-toggle="tab"> Reglamentos generales
					</a></li>
				<li class="nav-item"><a class="nav-link" href="#tab2" data-toggle="tab"> Reglamentos DEP </a></li>
			</ul>
			<div class="tab-content">

				<div class="tab-pane active" id="tab1">
					<div class="row">
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento General Estudios Posgrado<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_GENERAL_ESTUDIOS_POSGRADO.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Regulación Oferta Educativa <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_para_la_regulación_de_la_oferta_educativa.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento General de Investigación <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_General_de_Investigación.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->
					</div>
				</div>
				<div class="tab-pane" id="tab2">

					<div class="row">
						<!-- plan start -->
						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured">

								<span class="plan-name"> Reglamento de investigación IIC
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO-INVESTIGACI%C3%93N_IIC.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- plan start -->
						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name"> Reglamento de Investigación FTS <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_INVESTIGACION_DE_LA_FTS.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured ">
								<span class="plan-name">Reglamento de Investigación FADER/CIPOL
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_de_la_FADER_y_CIPOL.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->


						<!-- plan start -->
						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FAZ <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_INVESTIGACI%C3%93N_FAZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".7s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCB <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_INVESTIGACIÓN_FCB.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".7s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FP y TCH <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FPyTCH.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay=".7s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la DEP FMVZ <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_LA_DEP_DE_FMVZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay=".7s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la DEP FCB<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_LA_DEP_FCB.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Secretaria Posgrado FAMEN <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_Secretaria_de_posgradio_FAMEN.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la DEP FAEO<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DEP_FAEO.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Posgrado FAZ <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_POSGRADO_FAZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación MCYH <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_MCYH.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación MICAF <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_MICAF.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!--Nuevos reglamentos 13/01/2025-->
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCE <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FCE.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FCE <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_Dirección_de_Estudios_de_Posgrado_FCE.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FACSA <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FACSA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FACSA <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_Dirección_de_Estudios_de_Posgrado_FACSA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FAOD <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FAOD.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FAOD <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_División_de_Estudios_de_Posgrado_FAOD.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCCFyD <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/FCCFYD_Reglamento de Investigación.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FCCFyD <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/FCCFYD_Reglamento_División_de_Estudios_de_Posgrado.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Interno Doctorado en Biomedicina <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_Interno_Doctorado_en_Biomedicina.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCFyA <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FCFyA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FCFyA <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_División_de_Estudios_de_Posgrado_FCFyA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCQ campus Durango <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FCQ_campus_Durango.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FQC campus Durango <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_División_de_Estudios_de_Posgrado_FCQ_campus_Durango.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCQ campus Gómez Palacio <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_Facultad_de_Ciencias_Quimicas_campus_Gómez_Palacio.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FCQ campus Gómez Palacio  <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_División_de_Estudios_de_Posgrado_FCQ_campus_Gómez_Palacio.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FICA <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FICA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FICA<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_División_de_Estudios_de_Posgrado_FICA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Interno DIIE <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/DIE_Reglamento_Interno_DIIE.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Interno ICS <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/ICS_Reglamento_INV_Sellado.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación ISIMA <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_ISIMA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FTS <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FTS.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FTS<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Posgrado_FTS.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FADER<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_DEP_FADER.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento DEP FPyTCH<small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_DEP_FPYTCH.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!---->
					</div>
				</div>

			</div>
		</div><!-- End default tabs -->
		<div class="gap-40"></div>

	</div>
	<!--/  Container end-->

</section>
<!--/ Pricing table end -->



<app-footer></app-footer>